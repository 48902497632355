import React, { useEffect, useState } from 'react';
import { Container, Image } from "react-bootstrap";
import ExchangeRateSec from './ExchangeRateSec';
import { Link } from 'react-router-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import WalletAddressSec from './WalletAddressSec';
import { useDispatch, useSelector } from 'react-redux';
import { updateBuyData } from '../../store/actions/UserAction';
import Kyc from '../Common/Kyc';
import PaymentSec from './PaymentSec';
import './Buy.css';
import Login from "../Auth/Login"
import CardIndex from '../Card/CardIndex';

const BuyIndex = (props) => {

  const dispatch = useDispatch();
  const buyData = useSelector(state => state.user.buyData);
  const login = useSelector(state => state.user.login)
  const setBuyData = data => dispatch(updateBuyData(data));
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token"));
  const [skipRender, setSkipRender] = useState(true);

  const setStep = step => {
    setBuyData({
      ...buyData, step: step,
    })
  }

  useEffect(() => {
    if (buyData.step == 6) {
      setBuyData({
        ...buyData, step: 1,
      })
    }
  }, []);

  useEffect(()=> {
    if(!skipRender && !login.loading && Object.keys(login.data).length > 0)
    {
      setIsLoggedIn(localStorage.getItem("token"))
    }
    setSkipRender(false);
  }, [login]);

  useEffect(()=> {
    setIsLoggedIn(localStorage.getItem("token"))
  }, [localStorage.getItem("token")])

  return (
    <>
      <div className="exchange-sec">
        <Container>
          <div className="buy-crypto-sec">
            <Row className="justify-content-md-center">
              <Col md={10} xl={5} lg={7}>
                <div className="exchange-box">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="swap-crypto">
                    <div className="exchange-flex-card">
                      {buyData.step != 1 && buyData.step != 5 && buyData.step != 6 ?
                        <div className="back-arrow-btn-sec" onClick={() => {
                          if (buyData.step === 4) {
                            setStep(buyData.exchangeValue > 500 ? buyData.step - 1 : buyData.step - 2);
                          } else
                            setStep(buyData.step - 1)
                        }}>
                          <Image
                            src={window.location.origin + "/img/exchange/back-arrow-icon.svg"}
                            className="back-arrow-btn"
                          />
                        </div>
                        : null
                      }
                      <Nav variant="pills">
                        <Nav.Item>
                          <Link to="/buy" className="nav-link active">Buy Crypto</Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Link to="/swap" className="nav-link">Swap Crypto</Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Tab.Container>

                  {buyData.step == 1 ?
                    <div className="step-1">
                      <ExchangeRateSec />
                    </div>
                    : buyData.step == 2 ?
                      <div className="step-2">
                        <WalletAddressSec />
                      </div>
                      : buyData.step == 3 ?
                        <div className="step-3">
                          {isLoggedIn ? <Kyc isBuy={true} /> : <Login/>}
                        </div>
                        : buyData.step >= 4 ?
                          <div className="step-4">
                            <CardIndex />
                          </div>
                          : null}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );

}

export default BuyIndex;
import { all, fork } from 'redux-saga/effects';
import WalletConnectorSaga from './WalletConnectorSaga';
import UserSaga from './UserSaga';
import PageSaga from './PageSaga';

export default function* rootSaga() {
    yield all([
        fork(WalletConnectorSaga),
        fork(UserSaga),
        fork(PageSaga),
    ]);
}
import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { profileStart, updateBuyData, updateSwapData } from "../../../store/actions/UserAction";

const HeaderIndex = (props) => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const login = useSelector(state => state.user.login)
  const profile = useSelector(state => state.user.profile);
  const buyData = useSelector(state => state.user.buyData);
  const setBuyData = data => dispatch(updateBuyData(data));
  const swapData = useSelector(state => state.user.swapData);
  const setSwapData = data => dispatch(updateSwapData(data));
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token"));
  const [skipRender, setSkipRender] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    localStorage.getItem("token") && dispatch(profileStart());
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token")
    localStorage.removeItem("userId")
    setBuyData({
      ...buyData, step: 1,
    })
    setSwapData({
      ...swapData, step: 1,
    })
  }

  useEffect(()=> {
    if(!skipRender && !login.loading && Object.keys(login.data).length > 0)
    {
      setIsLoggedIn(localStorage.getItem("token"))
    }
    setSkipRender(false);
  }, [login]);

  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container>
            <Link to="/" className="navbar-brand">
              <Image
                className="logo"
                src={
                  window.location.origin + "/img/exchange-logo-black.png"
                }
              />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Image
                className="lazyload hamburger-menu"
                src={
                  window.location.origin + "/img/hamburger-menu.svg"
                }
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Link
                  className={`nav-link ${location.pathname === "/buy" ? "active" : ""}`}
                  to="/buy"
                >Buy / Swap Crypto</Link>
                <Link
                  className={`nav-link ${location.pathname === "/page/about" ? "active" : ""}`}
                  to="/page/about"
                  target="_blank"
                >About us</Link>
                <Link
                  className={`nav-link ${location.pathname === "/page/help" ? "active" : ""}`}
                  to="/page/help"
                  target="_blank"
                >Help Center</Link>
                {/* {isLoggedIn ? <Link to="#" onClick={handleLogout} className="nav-link">Logout</Link> : <Link
                  className={`nav-link ${location.pathname === "/login" ? "active" : ""}`}
                  to="/login"
                >Login</Link>} */}
                <div className="nav-item">
                  {!isLoggedIn ? (
                    <Link to="/login" className={`nav-link ${location.pathname === "/login" ? "active" : ""}`}>
                      Login
                    </Link>
                  ) : (!profile.loading &&
                    Object.keys(profile.data).length > 0) ? (
                    <div className="user-dropdown-sec">
                    <Dropdown onToggle={handleDropdownToggle} show={dropdownOpen}>
                      <Dropdown.Toggle id="dropdown-basic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#111111"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zM8 21.164V19c0-.552.449-1 1-1h6c.551 0 1 .448 1 1v2.164c-1.226.537-2.578.836-4 .836s-2.774-.299-4-.836zm10-1.169V19c0-1.654-1.346-3-3-3H9c-1.654 0-3 1.346-3 3v.995A9.991 9.991 0 012 12C2 6.486 6.486 2 12 2s10 4.486 10 10a9.992 9.992 0 01-4 7.995zM12 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>
                        </svg>
                        <span>{profile.data.name}</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link to="/profile" onClick={() => handleDropdownClose()}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                          </svg>
                          <span>Profile</span>
                        </Link>
                        <Link to="/transaction-history" onClick={() => handleDropdownClose()}>
                        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        d="M12 22.5c5.79 0 10.5-4.71 10.5-10.5S17.79 1.5 12 1.5 1.5 6.21 1.5 12 6.21 22.5 12 22.5zM12 3c4.965 0 9 4.035 9 9s-4.035 9-9 9-9-4.035-9-9 4.035-9 9-9z"
      ></path>
      <path
        fill="#000"
        d="M7.5 11.25h9a.75.75 0 100-1.5H8.902l.722-1.084a.75.75 0 10-1.248-.832l-1.5 2.25A.75.75 0 007.5 11.25zm0 3h7.6l-.724 1.084a.751.751 0 001.248.832l1.5-2.25a.75.75 0 00-.624-1.166h-9a.75.75 0 100 1.5z"
      ></path>
    </svg>
                          <span>Transactions</span>
                        </Link>
                        <Link to="/login" onClick={handleLogout}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M11.476 15a1 1 0 00-1 1v3a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2.476a3 3 0 013 3v3a1 1 0 002 0V5a5.006 5.006 0 00-5-5H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h2.476a5.006 5.006 0 005-5v-3a1 1 0 00-1-1z"></path>
                            <path d="M22.867 9.879l-4.586-4.586a1 1 0 10-1.414 1.414l4.263 4.263L6 11a1 1 0 000 2l15.188-.03-4.323 4.323a1 1 0 101.414 1.414l4.586-4.586a3 3 0 00.002-4.242z"></path>
                          </svg>
                          <span>Logout</span>
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  ) : null}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default HeaderIndex;

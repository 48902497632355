import React, { useEffect, useState } from 'react';
import { Image, Form, Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateSwapData } from '../../store/actions/UserAction';
import ExchangeRate from './ExchangeRate';
import "./Swap.css";
import SwapWalletAddress from './SwapWalletAddressSec';
import Kyc from '../Common/Kyc';
import SwapCryptoPayment from './SwapCryptoPayment';
import Login from '../Auth/Login';

const SwapIndex = () => {

  const dispatch = useDispatch();
  const swapData = useSelector(state => state.user.swapData);
  const setSwapData = data => dispatch(updateSwapData(data));
  const login = useSelector(state => state.user.login);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token"));
  const [skipRender, setSkipRender] = useState(true);


  const setStep = step => {
    setSwapData({
      ...swapData, step: step,
    })
  }

  useEffect(() => {
    if (swapData.step == 6) {
      setSwapData({
        ...swapData, step: 1,
      })
    }
  }, []);

  useEffect(()=> {
    if(!skipRender && !login.loading && Object.keys(login.data).length > 0)
    {
      setIsLoggedIn(localStorage.getItem("token"))
    }
    setSkipRender(false);
  }, [login]);

  useEffect(()=> {
    setIsLoggedIn(localStorage.getItem("token"))
  }, [localStorage.getItem("token")])

  return (
    <>

      <div className="exchange-sec">
        <Container>
          <div className="buy-crypto-sec">
            <Row className="justify-content-md-center">
              <Col md={10} xl={5} lg={7}>
                <div className="exchange-box">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="swap-crypto">
                    <div className="exchange-flex-card">
                      {swapData.step != 1 && swapData.step != 5 && swapData.step != 6 ?
                        <div className="back-arrow-btn-sec" onClick={() => setStep(swapData.step - 1)}>
                          <Image
                            src={window.location.origin + "/img/exchange/back-arrow-icon.svg"}
                            className="back-arrow-btn"
                          />
                        </div>
                        : null
                      }
                      <Nav variant="pills">
                        <Nav.Item>
                          <Link to="/buy" className="nav-link">Buy Crypto</Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Link to="/swap" className="nav-link active">Swap Crypto</Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Tab.Container>
                  {swapData.step === 1 ?
                    <ExchangeRate />
                    : swapData.step === 2 ?
                      <SwapWalletAddress />
                      : swapData.step === 3 ?
                        <>
                          {isLoggedIn ? <Kyc isBuy={false} /> : <Login/>}
                        </>
                      : swapData.step >= 4 ?
                        <SwapCryptoPayment />
                        : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SwapIndex;
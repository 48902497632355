import './App.css';
import React, { useState, useEffect } from 'react';
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import store from "./store";
import { Provider } from "react-redux";
import { apiConstants } from './components/Constant/constants'
import configuration from 'react-global-configuration'
import 'react-loading-skeleton/dist/skeleton.css'

import { WagmiConfig, createClient, chain, configureChains, useAccount } from 'wagmi';
import { Buffer } from "buffer";
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { swapChains } from './components/Common/Chains';

function App() {
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();
      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  const createWalletClient = () => {

    const defaultChains = swapChains.filter(chain => chain.isNetwork).map((chain, i) => {
      return {
        id: chain.id,
        name: chain.name,
        nativeCurrency: {
          decimals: chain.decimals,
          name: chain.name,
          symbol: chain.symbol,
        },
        rpcUrls: {
          default: chain.rpcUrl,
        },
        blockExplorers: {
          default: { name: chain.name, url: chain.blockExplorerUrl },
        },
        testnet: chain.testnet,
      }
    });

    if (!window.Buffer) window.Buffer = Buffer;

    const { chains, provider, webSocketProvider } = configureChains(defaultChains, [
      alchemyProvider({ apiKey: 'yourAlchemyApiKey' }),
      publicProvider(),
    ])

    const client = createClient({
      autoConnect: true,
      connectors: [
        new MetaMaskConnector({ chains }),
        new CoinbaseWalletConnector({
          chains,
          options: {
            appName: 'wagmi',
          },
        }),
        new WalletConnectConnector({
          chains,
          options: {
            qrcode: true,
          },
        }),
      ],
      provider,
      webSocketProvider,
    })
    return client;
  }

  return (
    <Provider store={store}>
      {configLoading ?
        null
        : <WagmiConfig client={createWalletClient()}>
          <Base />
        </WagmiConfig>
      }
    </Provider>
  );
}

export default App;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const ProfileLoader = () => {
  return (
    <div className="profile-sec">
      <div className="profile-box">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={12} xl={7} lg={9}>
              <div className="profile-header-sec">
                <div className="profile-header-left-sec">
                  <div className="profile-img-change-sec">
                    <Skeleton circle={true} height={100} width={100} />
                  </div>
                  <div className="profile-user-info">
                    <h4>
                      <Skeleton width={200} />
                    </h4>
                    <h5>
                      <Skeleton width={150} />
                    </h5>
                    <h5>
                      <Skeleton width={180} />
                    </h5>
                    <h5>
                      <Skeleton width={180} />
                    </h5>
                  </div>
                </div>
                <div className="profile-header-right-sec">
                  <Skeleton width={80} height={40} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ProfileLoader;
import React from "react";
import { Image } from "react-bootstrap";

const NoDataFound = () => {
  return (
    <>
      <div className="text-center">
        <Image
          className="no-data-img"
          src={window.location.origin + "/img/no-data-found.png"}
        />
        <div className="no-bot-exists-info">
          <h4>No Data Found !</h4>
        </div>
      </div>
    </>
  );
};

export default NoDataFound;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPageIndexOld from "../LandingPage/LandingPageIndexOld";
import EmptyLayout from "../layouts/EmptyLayout";
import MainLayout from "../layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import ExchangeIndex from "../ExchangeOld/ExchangeIndex";
import SwapIndexOld from "../SwapOld/SwapIndexOld";
import StaticIndex from "../Static/StaticIndex";
import BuyIndex from "../Buy/BuyIndex";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import SwapIndex from "../Swap/SwapIndex";
import LoginIndex from "../Auth/LoginIndex"
import RegisterIndex from "../Auth/RegisterIndex"
import ProfileIndex from "../Profile/ProfileIndex";
import EditProfileIndex from "../Profile/EditProfileIndex";
import CardIndex from "../Card/CardIndex";
import PaymentResponse from "../Buy/PaymentResponse";
import TransactionList from "../Card/TransactionList";

const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("token");
  let authentication =
    userId && accessToken ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [configLoading, setConfigLoading] = useState(true);


  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
        />
        {/* <script src={configuration.get("configData.head_scripts")}></script> */}
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={
              <AppRoute
                component={LandingPageIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/old"}
            element={
              <AppRoute
                component={LandingPageIndexOld}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/buy"}
            element={
              <AppRoute
                component={BuyIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/swap"}
            element={
              <AppRoute
                component={SwapIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/exchange"}
            element={
              <AppRoute
                component={ExchangeIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/swap-old"}
            element={
              <AppRoute
                component={SwapIndexOld}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/page/:static_page_unique_id"}
            element={
              <AppRoute
                component={StaticIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/login"}
            element={
              <AppRoute
                component={LoginIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/register"}
            element={
              <AppRoute
                component={RegisterIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/profile"}
            element={
              <PrivateRoute
                component={ProfileIndex}
                layout={MainLayout}
              />
            }
          />
           <Route
            path={"/edit-profile"}
            element={
              <PrivateRoute
                component={EditProfileIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/success"}
            element={
              <PrivateRoute
                component={PaymentResponse}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/fail"}
            element={
              <PrivateRoute
                component={PaymentResponse}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/transaction-history"}
            element={
              <PrivateRoute
                component={TransactionList}
                layout={MainLayout}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;

import React from "react";
import { Image, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { resetSwapData } from "../../store/actions/UserAction";

const SwapFailure = (props) => {
  const dispatch = useDispatch();


  return (
    <>
      <div className="buy-processing-img-sec">
        <Image
          src={window.location.origin + "/img/buy/payment-failure.gif"}
          className="buy-processing-img"
        />
      </div>
      <div className="payment-processing-countdown-card">
        <div className="payment-failure-text">
          Payment Failure
        </div>
      </div>
      <div className="exchange-btn-sec">
        <Button className="default-btn" onClick={() => dispatch(resetSwapData())}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default SwapFailure;

import React, { useEffect, useState } from "react";
import { Row, Container, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  createKycApplicantStart,
  profileUpdateStart
} from "../../store/actions/UserAction";
import { connect } from "react-redux";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import ProfileLoader from "../helper/ProfileLoader";
import KYCUpdateModal from "../KYC/KYCUpdateModal";

const ProfileIndex = (props) => {
  const [imgPreviewAdd, setImgPreviewAdd] = useState(null);
  const [kyc, setKyc] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const closeKycModal = () => {
    setKyc(false);
  };

  const imgPreview = (e) => {
    setImgPreviewAdd(URL.createObjectURL(e.target.files[0]));
    props.dispatch(profileUpdateStart({ 
      picture: e.target.files[0],
      name: props.profile.data.name,
      email: props.profile.data.email, 
      website_url: props.profile.data.website_url,
    }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.kycStatusUpdate.loading &&
      Object.keys(props.kycStatusUpdate.data).length > 0
    ) {
      closeKycModal();
    }
    setSkipRender(false);
  }, [props.kycStatusUpdate]);

  const verifyKyc = (e) => {
    e.preventDefault();
    props.dispatch(createKycApplicantStart());
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.kycApplicant.loading &&
      Object.keys(props.kycApplicant.data).length > 0
    ) {
      setKyc(true);
    }
    setSkipRender(false);
  }, [props.kycApplicant]);

  return (
    <>
      {props.profile.loading ? (
        <ProfileLoader />
      ) : (
        <>
          <div className="profile-sec">
            <div className="profile-box">
              <Container>
                <Row className="justify-content-md-center">
                  <Col md={12} xl={9} lg={9}>
                    <div className="profile-header-sec">
                      <div className="profile-header-left-sec">
                        <div className="profile-img-change-sec">
                          <CustomLazyLoad
                            src={
                              imgPreviewAdd
                                ? imgPreviewAdd
                                : props.profile.data.picture
                            }
                            placeholderSrc={
                              window.location.origin +
                              "/images/placeholder.jpeg"
                            }
                            classes="profile-image"
                          />
                          <label className="profile-img-edit-icon">
                            <input
                              type="file"
                              accept="image/png, image/jpeg,, image/jpg"
                              size="60"
                              onChange={(e) => imgPreview(e)}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                              width="35"
                              height="35"
                              fill="#3bba9c"
                            >
                              <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                            </svg>
                          </label>
                        </div>
                        <div className="profile-user-info">
                          <h4>{props.profile.data.name}</h4>
                          {props.profile.data.mobile && (
                            <h5>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 20 21"
                              >
                                <path
                                  stroke="#757575"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.3"
                                  d="M7.693 4.896a11.748 11.748 0 01-.43-2.137C7.188 2.037 6.558 1.5 5.832 1.5H2.463a1.47 1.47 0 00-1.457 1.61c.768 8.693 7.691 15.616 16.383 16.384A1.469 1.469 0 0019 18.04v-3.003c0-1.096-.537-1.724-1.26-1.8-.723-.077-1.439-.22-2.136-.43a2.39 2.39 0 00-2.375.607l-1.441 1.441a15.244 15.244 0 01-6.142-6.142l1.441-1.441a2.39 2.39 0 00.606-2.375z"
                                ></path>
                              </svg>
                              <span>{props.profile.data.mobile}</span>
                            </h5>
                          )}
                          {props.profile.data.email && (
                            <h5>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 20 13"
                              >
                                <path
                                  stroke="#757575"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.3"
                                  d="M18.59 1.367L10 7.355 1.41 1.367m.512-.355a.979.979 0 00-.652.242.785.785 0 00-.27.584v9.336c0 .22.097.43.27.584a.979.979 0 00.652.242h16.156a.979.979 0 00.652-.242.785.785 0 00.27-.584V1.826a.785.785 0 00-.27-.584.979.979 0 00-.652-.242H1.922v.012z"
                                ></path>
                              </svg>
                              <span>{props.profile.data.email}</span>
                            </h5>
                          )}
                          {props.profile.data.website_url && (
                            <h5>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#757575"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.647 7h-3.221a19.676 19.676 0 00-2.821-4.644A10.031 10.031 0 0120.647 7zM16.5 12a10.211 10.211 0 01-.476 3H7.976a10.211 10.211 0 01-.476-3 10.211 10.211 0 01.476-3h8.048a10.211 10.211 0 01.476 3zm-7.722 5h6.444A19.614 19.614 0 0112 21.588 19.57 19.57 0 018.778 17zm0-10A19.614 19.614 0 0112 2.412 19.57 19.57 0 0115.222 7zM9.4 2.356A19.676 19.676 0 006.574 7H3.353A10.031 10.031 0 019.4 2.356zM2.461 9H5.9a12.016 12.016 0 00-.4 3 12.016 12.016 0 00.4 3H2.461a9.992 9.992 0 010-6zm.892 8h3.221A19.676 19.676 0 009.4 21.644 10.031 10.031 0 013.353 17zm11.252 4.644A19.676 19.676 0 0017.426 17h3.221a10.031 10.031 0 01-6.042 4.644zM21.539 15H18.1a12.016 12.016 0 00.4-3 12.016 12.016 0 00-.4-3h3.437a9.992 9.992 0 010 6z"></path>
                              </svg>
                              <span>{props.profile.data.website_url}</span>
                            </h5>
                          )}
                          {props.profile.data.kyc_verified && (
                            <h5>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                fill="#757575"
                                height="20"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M10 23a1 1 0 01-1 1H5c-2.757 0-5-2.243-5-5V5c0-2.757 2.243-5 5-5h8c2.757 0 5 2.243 5 5v2a1 1 0 11-2 0V5c0-1.654-1.346-3-3-3H5C3.346 2 2 3.346 2 5v14c0 1.654 1.346 3 3 3h4a1 1 0 011 1zm4-17a1 1 0 00-1-1H5a1 1 0 100 2h8a1 1 0 001-1zm-4 5a1 1 0 00-1-1H5a1 1 0 100 2h4a1 1 0 001-1zm-5 4a1 1 0 100 2h2a1 1 0 100-2H5zm19 2c0 3.859-3.141 7-7 7s-7-3.141-7-7 3.141-7 7-7 7 3.141 7 7zm-2 0c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5zm-3.192-1.241l-2.223 2.134a.374.374 0 01-.522.002l-1.131-1.108a.999.999 0 10-1.4 1.428l1.132 1.109c.46.449 1.062.674 1.663.674s1.201-.225 1.653-.671l2.213-2.124a1 1 0 00-1.385-1.443z"></path>
                              </svg>
                              <span>KYC Verified</span>
                            </h5>
                          )}
                        </div>
                      </div>
                      <div className="profile-header-right-sec">
                        <Link to="/edit-profile" className="default-btn">
                          Edit
                        </Link>
                        {!props.profile.data.kyc_verified && (
                          <button onClick={verifyKyc} className="default-btn" disabled={props.kycApplicant.buttonDisable}>
                            {props.kycApplicant.buttonDisable
                              ? "Loading"
                              : " Verify KYC"}
                          </button>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          {kyc && <KYCUpdateModal kyc={kyc} closeKyc={closeKycModal} />}
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  kycStatusUpdate: state.user.updateKYC,
  kycApplicant: state.user.kycApplicant,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(ProfileIndex);

import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Button,
  Row,
  Container,
  Col,
} from "react-bootstrap";
import { profileUpdateStart } from "../../store/actions/UserAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import ProfileLoader from "../helper/ProfileLoader";

const EditProfileIndex = (props) => {
  const formRef = useRef(null);

  const [value, setValue] = useState();
  const [profileImg, setProfileImg] = useState(null);
  const [imgPreviewAdd, setImgPreviewAdd] = useState(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("invalid_email").required("required"),
    name: Yup.string().required("required"),
    website_url: Yup.string().required(("required"))
      .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,'invalid url!'),
  });

  const handleSubmit = (values) => {
    let newValues = values;
    if (profileImg) {
      newValues = {
        ...values,
        picture: profileImg,
      };
    }
    props.dispatch(profileUpdateStart(newValues));
  };

  const imgPreview = (e) => {
    setImgPreviewAdd(URL.createObjectURL(e.target.files[0]));
    setProfileImg(e.target.files[0]);
  };

  return (
    <>
      {props.profile.loading ? (
        <ProfileLoader />
      ) : (
        <div className="profile-sec">
          <div className="profile-box">
            <Container>
              <Row className="justify-content-md-center">
                <Col md={12} xl={8} lg={9}>
                  <div className="edit-profile-header-sec">
                    <div className="profile-header-left-sec">
                      <div className="profile-img-change-sec">
                        <CustomLazyLoad
                          src={
                            imgPreviewAdd
                              ? imgPreviewAdd
                              : props.profile.data.picture
                          }
                          placeholderSrc={
                            window.location.origin + "/images/placeholder.jpeg"
                          }
                          classes="profile-image"
                        />
                        <label className="profile-img-edit-icon">
                          <input
                            type="file"
                            accept="image/png, image/jpeg,, image/jpg"
                            size="60"
                            onChange={(e) => imgPreview(e)}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            width="35"
                            height="35"
                            fill="#3bba9c"
                          >
                            <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                          </svg>
                        </label>
                      </div>
                      <div className="profile-user-info">
                        <Formik
                          initialValues={{
                            name: props.profile.data.name,
                            email: props.profile.data.email,
                            website_url: props.profile.data.website_url,
                          }}
                          onSubmit={(values) => handleSubmit(values)}
                          validationSchema={validationSchema}
                          innerRef={formRef}
                        >
                          {({ errors, values, touched, setFieldValue }) => (
                            <FORM className="exchange-form">
                              <Form.Group
                                controlId="formBasicEmail"
                                className="mb-3"
                              >
                                <Form.Label>
                                  Full Name <span>*</span>
                                </Form.Label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder={"name_placeholder"}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="name"
                                  className="text-danger"
                                />
                              </Form.Group>
                              <Form.Group
                                controlId="formBasicEmail"
                                className="mb-3"
                              >
                                <Form.Label>
                                  Email <span>*</span>
                                </Form.Label>
                                <Field
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  placeholder={"email_placeholder"}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="email"
                                  className="text-danger"
                                />
                              </Form.Group>
                              <Form.Group
                                controlId="formBasicEmail"
                                className="mb-0"
                              >
                                <Form.Label>
                                  Website URL <span>*</span>
                                </Form.Label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="website_url"
                                  placeholder={"website_placeholder"}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="website_url"
                                  className="text-danger"
                                />
                              </Form.Group>
                              <div className="exchange-btn-sec">
                                <Button
                                  className="default-btn w-100"
                                  type="submit"
                                  disabled={
                                    props.updateProfile.buttonDisable
                                  }
                                >
                                  {props.updateProfile.buttonDisable
                                    ? "Loading"
                                    : "Save Changes"}
                                </Button>
                              </div>
                            </FORM>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  updateProfile: state.user.updateProfile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(EditProfileIndex);

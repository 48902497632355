import React from "react";
import { Form, InputGroup, Button, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { resetSwapData } from "../../store/actions/UserAction";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  getSuccessNotificationMessage
} from "../../components/helper/ToastNotification";

const SwapSuccess = (props) => {
  const dispatch = useDispatch();
  const swapToken = useSelector(state => state.user.swapToken);

  const onCopy = (event, type) => {
    getSuccessNotificationMessage(`${type} was copied to clipboard!`)
  };

  console.log(swapToken);

  return (
    <>
      <div className="buy-processing-img-sec">
        <Image
          src={window.location.origin + "/img/buy/payment-success.gif"}
          className="buy-success-img"
        />
      </div>
      <div className="payment-processing-countdown-card">
        <div className="payment-success-text">
          Payment Successful
        </div>
      </div>
      {Object.keys(swapToken.data).length > 0 && (
        <>
          <h6 className="mt-3">Transaction ID</h6>
          <InputGroup>
            <Form.Control
              placeholder="763ehj fewjebciwetr78324bfjbfwj3kefnw"
              value={swapToken.data.token_transaction.payment_id}
            />
            <InputGroup.Text id="basic-addon2">
              <CopyToClipboard
                text={swapToken.data.token_transaction.payment_id}
                onCopy={e => onCopy(e, 'Transaction ID')}
              >
                <Image
                  src={window.location.origin + "/img/sell/copy-icon.svg"}
                  className="swap-connect-icon"
                />
              </CopyToClipboard>
            </InputGroup.Text>
          </InputGroup>
          <h6 className="mt-3">{swapToken.data.token_transaction.token_type} Transaction Hash</h6>
          <InputGroup>
            <Form.Control
              placeholder="763ehj fewjebciwetr78324bfjbfwj3kefnw"
              value={swapToken.data.token_transaction.transaction_hash}
            />
            <InputGroup.Text id="basic-addon2">
              <CopyToClipboard
                text={swapToken.data.token_transaction.transaction_hash}
                onCopy={e => onCopy(e, 'Transaction Hash')}
              >
                <Image
                  src={window.location.origin + "/img/sell/copy-icon.svg"}
                  className="swap-connect-icon"
                />
              </CopyToClipboard>
            </InputGroup.Text>
          </InputGroup>
          <h6 className="mt-3">{swapToken.data.token_transaction.swap_token_type} Transaction Hash</h6>
          <InputGroup>
            <Form.Control
              placeholder="763ehj fewjebciwetr78324bfjbfwj3kefnw"
              value={swapToken.data.token_transaction.swap_transaction_hash}
            />
            <InputGroup.Text id="basic-addon2">
              <CopyToClipboard
                text={swapToken.data.token_transaction.swap_transaction_hash}
                onCopy={e => onCopy(e, 'Transaction Hash')}
              >
                <Image
                  src={window.location.origin + "/img/sell/copy-icon.svg"}
                  className="swap-connect-icon"
                />
              </CopyToClipboard>
            </InputGroup.Text>
          </InputGroup>
        </>
      )}
      <div className="exchange-btn-sec">
        <Button className="default-btn" onClick={() => dispatch(resetSwapData())}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default SwapSuccess;

import React from "react";
import { Image, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { resetBuyData } from "../../store/actions/UserAction";
import { useNavigate } from "react-router-dom";

const BuyFailure = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="buy-processing-img-sec">
        <Image
          src={window.location.origin + "/img/buy/payment-failure.gif"}
          className="buy-processing-img"
        />
      </div>
      <div className="payment-processing-countdown-card">
        <div className="payment-failure-text">Payment Failure</div>
      </div>
      <div className="exchange-btn-sec">
        <Button
          className="default-btn"
          onClick={() => {
            dispatch(resetBuyData());
            navigate("/buy");
          }}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default BuyFailure;

import { put, takeLatest, all } from "redux-saga/effects";
import api from "../../components/Environment";
import { 
  FETCH_STATIC_PAGE_START,
  FETCH_FAQS_LIST_START,
 } from "../actions/ActionConstant";
import {
  fetchStaticPageSuccess,
  fetchStaticPageFailure,
  fetchFaqsListSuccess,
  fetchFaqsListFailure,
} from "../actions/PageAction";

function* fetchStaticPage(action) {
  try {
    const response = yield api.postMethod(
      "static_pages_view", action.data
    );
    if (response.data.success) {
      yield put(fetchStaticPageSuccess(response.data.data));
    } else {
      yield put(fetchStaticPageFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchStaticPageFailure(error));
  }
}

function* fetchFaqsListAPI(action) {
  try {
    const response = yield api.postMethod("faqs_list", action.data);

    if (response.data.success) {
      yield put(fetchFaqsListSuccess(response.data.data));
    } else {
      yield put(fetchFaqsListFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchFaqsListFailure(error));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_STATIC_PAGE_START, fetchStaticPage),
    yield takeLatest(FETCH_FAQS_LIST_START, fetchFaqsListAPI),
  ]);
}

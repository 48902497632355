import React from 'react';
import Countdown from 'react-countdown';


const CounterTimer = (props) => {
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            props.onCompleted();
        } else {
            // Render a countdown
            return <div className="countdown-card">
                <div className="countdown-box">{(minutes < 10 ? "0" : "") + minutes}</div>
                <span>:</span>
                <div className="countdown-box">{(seconds < 10 ? "0" : "") + seconds}</div> 
                <p>seconds</p>
                </div>;
        }
    };
    return (
        <Countdown
            date={Date.now() + props.time}
            renderer={renderer}
        />
    )
}

export default React.memo(CounterTimer);
import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Button } from "react-bootstrap";
import "../SwapOld/Swap.css";
import Select, { components } from "react-select";
import "./eth.svg";
import "./binance.svg";
import "./solana.svg";
import "./tether.svg";
import ExchangeRateSec from "../Buy/ExchangeRateSec";
import ExchangeRate from "../Swap/ExchangeRate";
import { useSelector, useDispatch } from "react-redux";
import FAQIndex from "./FAQIndex";
import { profileStart, updateBuyData } from '../../store/actions/UserAction';
import { Link, useNavigate } from 'react-router-dom';
import { initialBuyData } from '../../store/reducers/UserReducer';
import { buyChains } from "../Common/Chains";

const LandingPageIndex = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const buyData = useSelector(state => state.user.buyData);
  const swapData = useSelector(state => state.user.swapData);
  const amountConversion = useSelector(state => state.walletConnector.amountConversion);
  const setBuyData = data => dispatch(updateBuyData(data));

  const [tab, setTab] = useState("buy");

  const redirectBuy = (token_type) => {
    setBuyData({
      ...initialBuyData,
      token: 0,
      token_type: token_type,
    })
    navigate("/buy");
  }

  return (
    <>
      <div className="landing-sec">
        <Container>
          <div className="vertical-center">
            <Row className="align-items-center">
              <Col md={12} xl={6} lg={12}>
                <div className="banner-content">
                  <h1>Lighting Cryptocurrency <span>Exchange</span></h1>
                  <p>Learn about cryptocurrency, NFTs, and blockchain, discover our latest product updates, partnership announcements, user stories, and more</p>
                  <div className="banner-link-sec">
                    <ul className="list-unstyled banner-link">
                      {buyChains.map((chain) => (
                        <li>
                        <a onClick={() => redirectBuy(chain)}>
                          <Image
                            src={chain.icon}
                            className="banner-exchange-icon"
                          />
                          <span>Buy {chain.name}</span>
                        </a>
                      </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md={12} xl={6} lg={12}>
                <div className="landing-swap-bg-right-sec">
                </div>
                <div className="landing-page-right-sec">
                  <div className="landing-swap-box">
                    <Tab.Container id="left-tabs-example" activeKey={tab}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="buy" onClick={() => setTab("buy")}>Buy Crypto</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="swap" onClick={() => setTab("swap")}>Swap Crypto</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Tab.Container>
                  </div>
                  <div className="landing-swap-exchange-box">
                    <div className="landing-swap-exchange-card">
                      {tab === "buy" ?
                        <ExchangeRateSec redirect={true} />
                        : <ExchangeRate redirect={true} />
                      }
                      <div className="landing-swap-exchange-rate-sec">
                        {tab === "buy" ?
                          < p > Estimated Rate 1 {buyData.token_type?.symbol} = {buyData.amountConversion} USD</p>
                          : <p>Estimated Rate 1 {swapData.token_type?.symbol} = {swapData.amountConversion} {swapData.swap_token_type?.symbol}</p>
                        }
                        <p>No Extra Fee</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div >
      <FAQIndex />
    </>
  );
};

export default LandingPageIndex;

import React from "react";
import { Image } from "react-bootstrap";
import CounterTimer from "../helper/CounterTimer";

const Processing = (props) => {


  return (
    <>
      <div className="buy-processing-img-sec">
        <Image
          src={window.location.origin + "/img/buy/payment-processing.gif"}
          className="buy-processing-img"
        />
      </div>
      <div className="payment-processing-countdown-card">
        <div className="payment-text">
          Payments In
        </div>
        <CounterTimer
          time={480000}
        />
      </div>
    </>
  );
};

export default Processing;

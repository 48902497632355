import React, { useState } from "react";
import FooterIndex from "./Footer/FooterIndex";
import HeaderIndex from "./Header/HeaderIndex";

const MainLayout = (props) => {

  return (
    <>
      <HeaderIndex />
      <div className="main-wrapper">
        {props.children}
      </div>
      <FooterIndex/>
    </>
  );
};

export default MainLayout;

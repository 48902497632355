import SumsubWebSdk from "@sumsub/websdk-react";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createKycApplicantStart, updateBuyData, updateKYCStart, updateSwapData } from "../../store/actions/UserAction";
import SomethingWentWrong from "./SomethingWentWrong";


const Kyc = ({ isBuy }) => {
  const dispatch = useDispatch();
  const kycApplicant = useSelector(state => state.user.kycApplicant);
  const profile = useSelector(state => state.user.profile);
  const data = useSelector(state => isBuy ? state.user.buyData : state.user.swapData);
  const setData = data => dispatch(isBuy ? updateBuyData(data) : updateSwapData(data));

  const [skipRender, setSkipRender] = useState(true);
  const [isKycVerified, setIsKycVerified] = useState(false);

  useEffect(() => {
    if (!data.accessToken) dispatch(createKycApplicantStart());
  }, []);

  useEffect(() => {
    if (!skipRender && !kycApplicant.loading && Object.keys(kycApplicant.data).length > 0) {
      setData({ ...data, accessToken: kycApplicant.data.token });
    }
    setSkipRender(false);
  }, [kycApplicant]);

  const options = {}
  const messageHandler = (type, payload) => {
    if (type == "idCheck.applicantReviewComplete" && payload.authCode) {
      setData({
        ...data,
        kyc_authCode: payload.authCode
      });
      dispatch(updateKYCStart({
        sumsub_id: payload.authCode,
      }));
    }
  }
  const errorHandler = (data) => console.log("onError", data)
  const accessTokenExpirationHandler = () => { }

  useEffect(()=> {
    if(!profile.loading && Object.keys(profile.data).length > 0)
    {
      setIsKycVerified(profile.data.kyc_verified);
    }
  }, [profile])

  useEffect(()=> {
    isKycVerified && setData({
      ...data,
      step: data.step + 1,
    })
  }, [isKycVerified])

  return (
    <>
      {kycApplicant.loading && !data.accessToken ?
        <div className="round-icon mt-5 mb-5">
          <svg version="1.1" role="presentation" width="13.714285714285714" height="16" viewBox="0 0 1536 1792" className="icon" fill="current">
            <path d="M1408 128q0 261-106.5 461.5t-266.5 306.5q160 106 266.5 306.5t106.5 461.5h96q14 0 23 9t9 23v64q0 14-9 23t-23 9h-1472q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h96q0-261 106.5-461.5t266.5-306.5q-160-106-266.5-306.5t-106.5-461.5h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h1472q14 0 23 9t9 23v64q0 14-9 23t-23 9h-96zM874 836q77-29 149-92.5t129.5-152.5 92.5-210 35-253h-1024q0 132 35 253t92.5 210 129.5 152.5 149 92.5q19 7 30.5 23.5t11.5 36.5-11.5 36.5-30.5 23.5q-77 29-149 92.5t-129.5 152.5-92.5 210-35 253h1024q0-132-35-253t-92.5-210-129.5-152.5-149-92.5q-19-7-30.5-23.5t-11.5-36.5 11.5-36.5 30.5-23.5z"></path>
          </svg>
        </div>
        : data.accessToken ?
          <>
            <SumsubWebSdk
              accessToken={data.accessToken}
              expirationHandler={accessTokenExpirationHandler}
              config={{}}
              options={options}
              onMessage={messageHandler}
              onError={errorHandler}
            />
            {data.kyc_authCode ?
              <div className="exchange-btn-sec">
                <Button className="default-btn" onClick={() => setData({
                  ...data,
                  step: data.step + 1,
                })} >
                  Continue
                </Button>
                {/* <p>By continuing you agree to our <Link to="#">cookie policy.</Link></p> */}
              </div>
              : null
            }
          </>
          : <SomethingWentWrong btnText="Retry" onClick={() => dispatch(createKycApplicantStart())} />
      }
    </>
  );
}

export default Kyc;
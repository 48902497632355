import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../SwapOld/Swap.css";
import Select, { components } from "react-select";
import "./eth.svg";
import "./binance.svg";
import "./solana.svg";
import "./tether.svg";

const LandingPageIndexOld = (props) => {


  const options = [
    { value: "Etherium", label: "Etherium", icon: "eth.svg" },
    { value: "Binance", label: "Binance", icon: "binance.svg" },
    { value: "Solana", label: "Solana", icon: "solana.svg" },
    { value: "Tether", label: "Tether", icon: "tether.svg" },
];

const { Option } = components;
const IconOption = props => (
    <Option {...props} className="select-option">
        <img
            src={require('./' + props.data.icon)}
            style={{ width: 30, height: 30 }}
            alt={props.data.label}
        />
         {props.data.label}
    </Option>
);

const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999, left: '0px' }),
    menuList: provided => ({
        ...provided,
        padding: 0,
        minWidth: 100,
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          borderRadius: "3px",
          backgroundColor: "#F5F5F5",
        },
        "&::-webkit-scrollbar": {
          width: "4px",
          backgroundColor: "#F5F5F5",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "3px",
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
          backgroundColor: "#555",
        }
      }),
      container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
        ...provided,
        backgroundColor: 'transparent',
        borderColor: 'transparent', border: 'none!important',
        boxShadow: 'none',
        paddingLeft:'0',
        minHeight:'57px',
    }),
    valueContainer: provided =>({
        ...provided,
        paddingLeft:'0'
    }),
    singleValue: provided => ({ ...provided, color: '#111111' }),
    indicatorContainer: provided => ({ ...provided, color: '#fff!important' }),
    indicatorContainer: provided => ({ ...provided, fill: '#fff!important' }),
    indicatorSeparator: base => ({
        ...base,
        display: 'none'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        "svg": {
            fill: "#111"
        }
    }),
    ///.....
}

  return (
    <>
      <div className="landing-sec">
        <Container>
          <div className="vertical-center">
            <Row>
              <Col md={6}>
                <div className="banner-content">
                  <h1>Lighting Cryptocurrency <span>Exchange</span></h1>
                  <p>Learn about cryptocurrency, NFTs, and blockchain, discover our latest product updates, partnership announcements, user stories, and more</p>
                  <div className="banner-link-sec">
                    <ul className="list-unstyled banner-link">
                      <li>
                        <Link to="#">
                          <Image
                            src={window.location.origin + "/img/landing/bitcoin.svg"}
                            className="banner-exchange-icon"
                          />
                          <span>Buy Bitcoin</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <Image
                            src={window.location.origin + "/img/landing/eth.svg"}
                            className="banner-exchange-icon"
                          />
                          <span>Buy Ethereum</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <Image
                            src={window.location.origin + "/img/landing/binance.svg"}
                            className="banner-exchange-icon"
                          />
                          <span>Buy Binance</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <Image
                            src={window.location.origin + "/img/landing/litecoin.svg"}
                            className="banner-exchange-icon"
                          />
                          <span>Buy Litecoin</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <Image
                            src={window.location.origin + "/img/landing/tether.svg"}
                            className="banner-exchange-icon"
                          />
                          <span>Buy Tether</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <Image
                            src={window.location.origin + "/img/landing/solana.svg"}
                            className="banner-exchange-icon"
                          />
                          <span>Buy Solana</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="landing-swap-bg-right-sec">
                </div>
                <div className="landing-page-right-sec">
                  <div className="landing-swap-box">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="swap-crypto">
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="buy-crypto">Buy Crypto</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="swap-crypto">Swap Crypto</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Tab.Container>
                  </div>
                  <div className="landing-swap-exchange-box">
                    <div className="landing-swap-exchange-card">
                      <div className="border-theme-bottom">
                        <div className="landing-swap-exchange-conversion-left-sec">
                          <div className="landing-swap-exchange-conversion-label">
                            <Image
                              src={window.location.origin + "/img/exchange/bitcoin-icon.svg"}
                              className="exchange-conversion-icon"
                            />
                            BTC
                          </div>
                          <div className="exchange-select-sec">
                            <Select
                              defaultValue={options[0]}
                              options={options}
                              components={{ Option: IconOption }}
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'}
                              styles={customStyles}
                              isSearchable={false}
                            // menuIsOpen={true} 
                            />
                          </div>
                        </div>
                      </div>
                      <div className="landing-swap-exchange-conversion-right-sec">
                        <h5>You Send</h5>
                        <h3>0.1</h3>
                      </div>
                    </div>
                    <div className="landing-swap-exchange-card">
                      <div className="border-theme-bottom">
                        <div className="landing-swap-exchange-conversion-left-sec">
                          <div className="landing-swap-exchange-conversion-label">
                            <Image
                              src={window.location.origin + "/img/exchange/eth.svg"}
                              className="exchange-conversion-icon"
                            />
                            ETH
                          </div>
                          <div className="exchange-select-sec">
                            <Select
                              defaultValue={options[0]}
                              options={options}
                              components={{ Option: IconOption }}
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'}
                              styles={customStyles}
                              isSearchable={false}
                            // menuIsOpen={true} 
                            />
                          </div>
                        </div>
                      </div>
                      <div className="landing-swap-exchange-conversion-right-sec">
                          <h5 className="text-para">You Get</h5>
                          <h3>1.625689</h3>
                      </div>
                    </div>
                    <div className="landing-swap-transfer-icon-sec">
                      <Image
                          src={window.location.origin + "/img/exchange/swap-transfer-icon.svg"}
                          className="swap-icon"
                      />
                    </div>
                  </div>
                  <div className="landing-swap-exchange-btn-sec">
                    <Button className="default-btn">
                        Swap
                    </Button>
                </div>
                <div className="landing-swap-exchange-rate-sec">
                  <p>Estimated Rate 1 BTC = 16.2146698 ETH</p>
                  <p>No Extra Fee</p>
                </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default LandingPageIndexOld;

import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { parseEther } from "ethers/lib/utils";
import {
  usePrepareSendTransaction,
  useSendTransaction,
  useNetwork,
  useSwitchNetwork,
  useWaitForTransaction,
  useBalance,
} from "wagmi";
import configuration from "react-global-configuration";
import { Button } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import Web3 from "web3";
import { BigNumber, ethers } from 'ethers';
import { getErrorNotificationMessage } from '../helper/ToastNotification';
import { swapTokenStart, updateSwapData } from '../../store/actions/UserAction';
import { fetchBalance } from '@wagmi/core'

const PaymentBtn = ({ isLoading, setIsLoading, ...props }) => {
  const dispatch = useDispatch();
  const swapData = useSelector(state => state.user.swapData);
  const setSwapData = data => dispatch(updateSwapData(data));
  const swapToken = useSelector(state => state.user.swapToken);

  const constants = configuration.get("configData");
  
  const network = useNetwork();
  const networkSwitcher = useSwitchNetwork();

  //Metamast Payment
  const [debouncedTo] = useDebounce(constants.admin_wallet_address, 500);
  const [allowedTokens, setAllowedTokens] = useState(0);
  // const [debouncedValue] = useDebounce(swapData.exchangeValue, 500);
  const web3 = new Web3(window.ethereum);
  const decimals = web3.utils.toBN(18);
  let tokens = swapData.swap_token;

  useEffect(() => {
    try {
      setAllowedTokens(web3.utils.toWei(tokens.toString(), "Ether"));
    } catch (error) {
      getErrorNotificationMessage(error.message);
    }
  }, []);

  const { config } = usePrepareSendTransaction({
    request: {
      to: debouncedTo,
      value: BigNumber.from(allowedTokens),
    },
  })
  const { data, sendTransaction } = useSendTransaction({
    ...config,
    onError(error) {
      console.log('Error', error)
      setIsLoading(false);
    },
  });

  const transactionWait = useWaitForTransaction({
    hash: data?.hash,
    onSuccess(data) {
      console.log(data);
      paymentFinished(data.transactionHash);
    },
    onError(error) {
      setIsLoading(false);
    }
  })

  const paymentViaMetaMask = () => {
    sendTransaction?.();
    setIsLoading(true);
  }

  const paymentFinished = (transactionHash) => {
    console.log("Buy Data", swapData);
    setTimeout(() => {
      dispatch(swapTokenStart({
        token_type: swapData.token_type.symbol,
        swap_token_type: swapData.swap_token_type.symbol,
        swap_tokens: swapData.swap_token,
        receiver_wallet_address: swapData.receiver_wallet_address,
        swap_transaction_hash: transactionHash,
      }));
      setSwapData({
        ...swapData,
        swap_transaction_hash: transactionHash,
        step: swapData.step + 1,
      })
    }, 20000);
  }

  const balance = useBalance({
    addressOrName: props.address,
    chainId: network.chain?.id,
  });

  console.log(balance, network.chain?.id, props.address)

  return (
    <>
      {network.chain?.id == swapData.swap_token_type?.id ?
        Number(balance.data?.formatted) > swapData.exchangeValue ? 
        < Button
          className="default-btn"
          disabled={isLoading || swapToken.buttonDisable || allowedTokens == 0}
          onClick={() => paymentViaMetaMask()}>
          {isLoading || swapToken.buttonDisable ?
            "Processing..."
            : "Pay via Metamask"
          }
        </Button>
        : <Button
            className="default-btn"
            onClick={() => getErrorNotificationMessage("Insufficent Balance")}>
            Pay via Metamask
          </Button>
        : <Button
          className="default-btn"
          disabled={swapToken.buttonDisable}
          onClick={() => networkSwitcher.switchNetwork(swapData.swap_token_type.id)}>
          Switch to {swapData.swap_token_type.label}
        </Button>
      }
    </>
  );
}

export default PaymentBtn;
import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
  Tab,
  Nav,
} from "react-bootstrap";
import SumsubWebSdk from "@sumsub/websdk-react";
import CenterLoader from "../helper/CommonCenterLoader";
import { updateKYCStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";

const KYCUpdateModal = (props) => {

  const errorHandler = (data) => console.log("onError", data);
  const accessTokenExpirationHandler = () => {};
  const [skip, setSkip] = useState(false);

  const options = {};
  const messageHandler = (type, payload) => {
    if (type == "idCheck.applicantReviewComplete" && payload.authCode) {
      if(!skip)
      {
        props.dispatch(
          updateKYCStart({
            sumsub_id: payload.authCode,
          })
        );
      }
    }
    setSkip(true)
  };

  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.kyc}
        onHide={props.closeKyc}
      >
        <Modal.Header closeButton>
          <Modal.Title>KYC Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.kycApplicant.loading ? (
            <div className="kyc-modal-loader-sec">
              <CenterLoader />
            </div>
          ) : Object.keys(props.kycApplicant.data).length > 0 ? (
            <SumsubWebSdk
              accessToken={props.kycApplicant.data.token}
              expirationHandler={accessTokenExpirationHandler}
              config={{}}
              options={options}
              onMessage={messageHandler}
              onError={errorHandler}
            />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  kycApplicant: state.user.kycApplicant,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(KYCUpdateModal);

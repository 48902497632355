import React from "react";
import { Image, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CustomSelect from "../Common/CustomSelect";
import { swapChains } from "../Common/Chains";
import { useDispatch, useSelector } from "react-redux";
import { updateSwapData } from "../../store/actions/UserAction";
import { initialSwapData } from "../../store/reducers/UserReducer";
import { useEffect } from "react";
import { useState } from "react";
import { amountConversionStart } from "../../store/actions/WalletConnectorAction";
import { useAccount, useDisconnect } from "wagmi";

const ExchangeRate = ({ redirect = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const amountConversion = useSelector(state => state.walletConnector.amountConversion);
  const swapData = useSelector(state => state.user.swapData);
  const setSwapData = data => dispatch(updateSwapData(data));

  const [skipRender, setSkipRender] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (!swapData.token_type) {
        setSwapData({
          ...swapData,
          swap_token_type: swapChains[0],
          token_type: swapChains[1],
        })
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (!skipRender) {
      dispatch(amountConversionStart({
        convertFrom: swapData.swap_token_type.apiId,
        convertTo: swapData.token_type.apiId,
      }));
    }
  }, [swapData.token_type, swapData.swap_token_type, swapData.swap_token]);

  useEffect(() => {
    if (!skipRender) disconnect();
  }, [swapData.swap_token_type])

  useEffect(() => {
    if (!skipRender && !amountConversion.loading && Object.keys(amountConversion.data).length > 0) {
      const exchangeValue = Math.round((amountConversion.data.amount * swapData.swap_token) * Math.pow(10, 8)) / Math.pow(10, 8)
      setSwapData({
        ...swapData,
        exchangeValue: exchangeValue,
        amountConversion: amountConversion.data.amount,
      });
      if (!exchangeValue || !swapData.swap_token)
        setErrorMsg("Invalid data");
      else
        setErrorMsg("");
    }
    setSkipRender(false);
  }, [amountConversion]);

  const onTokenChange = value => {
    if (Number(value) == value) {
      setSwapData({
        ...initialSwapData,
        swap_token: value,
        token_type: swapData.token_type,
        swap_token_type: swapData.swap_token_type,
        exchangeValue: swapData.exchangeValue,
      });
    }
  }

  const onComplete = e => {
    e.preventDefault();
    if (swapData.exchangeValue && swapData.swap_token) {
      if (redirect) {
        if (swapData.step == 6) {
          setSwapData({
            ...initialSwapData,
            swap_token: swapData.swap_token,
            token_type: swapData.token_type,
            swap_token_type: swapData.token_type,
            exchangeValue: swapData.exchangeValue,
          })
        }
        navigate("/swap");
      }
      else {
        setSwapData({ ...swapData, step: swapData.step + 1 })
      }
    }
  }


  return (
    <>
      {swapChains.length > 1 ?
        <div className="exchange-conversion-box">
          <div className="exchange-conversion-card">
            <div className="border-theme-right">
              <div className="exchange-conversion-left-sec">
                <div className="exchange-conversion-label">
                  <Image
                    src={swapData.swap_token_type?.icon}
                    className="exchange-conversion-icon"
                  />
                  {swapData.swap_token_type?.symbol}
                </div>
                <div className="exchange-select-sec">
                  <CustomSelect
                    options={swapChains.filter(data => data.value != swapData.token_type?.value)}
                    value={swapData.swap_token_type}
                    onChange={val => setSwapData({
                      ...initialSwapData,
                      swap_token: swapData.swap_token,
                      token_type: swapData.token_type,
                      swap_token_type: val,
                      exchangeValue: swapData.exchangeValue,
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="exchange-conversion-right-sec">
              <h5>You Send</h5>
              <input
                className="form-exchange-input"
                value={swapData.swap_token}
                autoFocus={true}
                onChange={e => onTokenChange(e.target.value)} />
            </div>
          </div>
          <div className="swap-transfer-icon-sec">
            <span className="or-line">
              <span>
                <Image
                  src={window.location.origin + "/img/exchange/swap-transfer-icon.svg"}
                  className="swap-icon"
                />
              </span>
            </span>
          </div>
          <div className="exchange-conversion-card">
            <div className="border-theme-right">
              <div className="exchange-conversion-left-sec">
                <div className="exchange-conversion-label">
                  <Image
                    src={swapData.token_type?.icon}
                    className="exchange-conversion-icon"
                  />
                  {swapData.token_type?.symbol}
                </div>
                <div className="exchange-select-sec">
                  <CustomSelect
                    options={swapChains.filter(data => data.value != swapData.swap_token_type?.value)}
                    value={swapData.token_type}
                    onChange={val => setSwapData({
                      ...initialSwapData,
                      swap_token: swapData.swap_token,
                      token_type: val,
                      swap_token_type: swapData.swap_token_type,
                      exchangeValue: swapData.exchangeValue,
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="exchange-conversion-right-sec">
              <h5 className="text-para">You Get</h5>
              <h3>{swapData.exchangeValue}</h3>
            </div>
          </div>
          <div className="exchange-btn-sec">
            <Button
              className="default-btn"
              onClick={swapData.swap_token > 0 ? onComplete : ""}
              disabled={amountConversion.buttonDisable}
            >
              {redirect ? "Swap" : "Continue"}
            </Button>
            {!redirect ?
              <p>By continuing you agree to our <Link to="#">cookie policy.</Link></p>
              : null
            }
          </div>
          {errorMsg ?
            <div className="error-card mt-3">
              <Image
                src={window.location.origin + "/img/warning-icon.svg"}
                className="exchange-warning-icon"
              />
              <span>{errorMsg}</span>
            </div>
            : null
          }
        </div>
        : "No Swap Available"
      }
    </>
  );
}

export default ExchangeRate;
import React from 'react';
import { Link } from 'react-router-dom';
import { Form, InputGroup, Button, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { swapTokenStart, updateSwapData } from '../../store/actions/UserAction';
import Processing from '../Common/Processing';
import SwapSuccess from './SwapSuccess';
import SwapFailure from './SwapFailure';
import PaymentBtn from './PaymentBtn';
import { useState } from 'react';
import { useAccount } from 'wagmi';
import configuration from 'react-global-configuration';
import WalletConnectorIndex from '../WalletConnector/WalletConnectorIndex';
import { useEffect } from 'react';
import CopyToClipboard from "react-copy-to-clipboard";
import {
  getSuccessNotificationMessage
} from "../../components/helper/ToastNotification";

const SwapCryptoPayment = () => {
  const dispatch = useDispatch();
  const swapToken = useSelector(state => state.user.swapToken);
  const swapData = useSelector(state => state.user.swapData);
  const connectedWallet = useSelector(state => state.walletConnector.connectedWallet);
  const setSwapData = data => dispatch(updateSwapData(data));

  const [isLoading, setIsLoading] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const constants = configuration.get("configData");

  useEffect(() => {
    setSkipRender(false);
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    dispatch(swapTokenStart({
      token_type: swapData.token_type.symbol,
      swap_token_type: swapData.swap_token_type.symbol,
      swap_tokens: swapData.swap_token,
      receiver_wallet_address: swapData.receiver_wallet_address,
      swap_transaction_hash: swapData.swap_transaction_hash,
    }));
    setSwapData({
      ...swapData,
      step: swapData.step + 1,
    })
  }

  const onCopy = (event, type) => {
    getSuccessNotificationMessage(`${type} was copied to clipboard!`)
  };

  return (
    <>
      {swapData.step === 4 ?
        <Form className="exchange-form" onSubmit={onSubmit}>
          <div className="receive-token-sec">
            <h5>Crypto Payment</h5>
          </div>
          <div className="swap-scan-item">
            <div className="swap-scan-box">
              <Image
                src={window.location.origin + "/img/sell/scan-qr-code.png"}
                className="swap-scan-icon"
              />
              <p>Scar QR Code</p>
            </div>
            <WalletConnectorIndex isConnectOnly={false} chainId={swapData.swap_token_type.id} />
          </div>
          <InputGroup className="mb-4">
            <Form.Control
              placeholder="763ehj fewjebciwetr78324bfjbfwj3kefnw"
              value={constants.admin_wallet_address}
            />
            <InputGroup.Text id="basic-addon2">
              <CopyToClipboard
                text={constants.admin_wallet_address}
                onCopy={e => onCopy(e, 'Wallet Address')}
              >
                <Image
                  src={window.location.origin + "/img/sell/copy-icon.svg"}
                  className="swap-connect-icon"
                />
              </CopyToClipboard>
            </InputGroup.Text>
          </InputGroup>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Paste Your Transaction Hash</Form.Label>
            <Form.Control
              type="text"
              placeholder="0xf8cd220e2687c03f3627a59023e57d57fc93434c8cc"
              value={swapData.swap_transaction_hash}
              disabled={isLoading}
              onChange={e => setSwapData({
                ...swapData,
                swap_transaction_hash: e.target.value,
              })}
            />
          </Form.Group>
          <div className="exchange-btn-sec">
            {swapData.swap_transaction_hash ?
              <Button className="default-btn" type="submit">
                Continue
              </Button>
              : connectedWallet.address && !skipRender ?
                <PaymentBtn isLoading={isLoading} setIsLoading={setIsLoading} address={connectedWallet.address} />
                : null
            }
            <p>By continuing you agree to our <Link to="#">cookie policy.</Link></p>
          </div>
        </Form>
        : <>
          {swapToken.loading ?
            <Processing />
            : Object.keys(swapToken.data).length > 0 ?
              <SwapSuccess />
              : <SwapFailure />
          }
        </>
      }
    </>
  );
}

export default SwapCryptoPayment;
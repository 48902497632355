import React, { useEffect, useState } from 'react';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';
import { setWalletDetails } from '../../store/actions/WalletConnectorAction';
import WalletConnectorModal from './WalletConnectorModal';
import { Button, Image } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

const NetWorkConnected = ({ className, chainId, isConnectOnly, ...props }) => {
	const dispatch = useDispatch();
	const network = useNetwork();

	const { disconnect } = useDisconnect({
		onError(error) {
			console.log('Error', error)
		},
		onMutate(args) {
			console.log('Mutate', args)
		},
		onSettled(data, error) {
			console.log('Settled', { data, error })
		},
		onSuccess(data) {
			dispatch(setWalletDetails());
		},
	});

	useEffect(() => {
		if (chainId && chainId !== network.chain?.id) {
			disconnect();
		}
	}, [network.chain?.id]);

	return (
		<>{isConnectOnly ?
			<Button className={className} onClick={() => disconnect()} disabled={props.disabled}>
				Disconnect
			</Button>
			: <div className="swap-connect-card" onClick={() => disconnect()}>
				<Image
					src={window.location.origin + "/img/sell/connect-metamask-icon-1.png"}
					className="swap-connect-icon"
				/>
				<p>Disconnect</p>
			</div>
		}
		</>
	);
}

const WalletConnectorIndex = ({ className = "", chainId = null, isConnectOnly = true, ...props }) => {
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);

	const hideConnectorModal = () => {
		setShow(false);
	}

	const account = useAccount();

	useEffect(() => {
		if (account.address) dispatch(setWalletDetails({ ...account, lastConnectedAddress: account.address }));
		else dispatch(setWalletDetails());
	}, [account.address]);


	return (
		<>
			{account.address ?
				<NetWorkConnected className={className} chainId={chainId} isConnectOnly={isConnectOnly} disabled={props.disabled} />
				: isConnectOnly ?
					<Button
						className={className}
						onClick={e => setShow(true)}
						disabled={props.disabled}
					>
						<Image
							src={window.location.origin + "/img/sell/connect-metamask-icon.png"}
							className="connect-meta-icon"
						/>
						Connect to Wallet
					</Button>
					: <div className="swap-connect-card" onClick={e => setShow(true)}>
						<Image
							src={window.location.origin + "/img/sell/connect-metamask-icon-1.png"}
							className="swap-connect-icon"
						/>
						<p>Connect Metamask</p>
					</div>
			}
			{show ?
				<WalletConnectorModal
					show={show}
					hideConnectorModal={hideConnectorModal}
					chainId={chainId}
				/>
				: null
			}
		</>
	);
}

const mapStateToProps = (state) => ({
	connectedWallet: state.walletConnector.connectedWallet,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(WalletConnectorIndex);
import React, { useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Web3 from 'web3';
import { updateBuyData } from '../../store/actions/UserAction';

const WalletAddressSec = (props) => {
  const dispatch = useDispatch();
  const buyData = useSelector(state => state.user.buyData);
  const setBuyData = data => dispatch(updateBuyData(data));

  const [isValidAddressMsg, setIsValidAddressMsg] = useState(false);

  const validateWalletAddress = () => {
    if (buyData.receiver_wallet_address)
      if (buyData.receiver_wallet_address.trim() === buyData.receiver_wallet_address) {
        const web3 = new Web3(window.ethereum);
        if (web3.utils.isAddress(buyData.receiver_wallet_address)) {
          let step = buyData.exchangeValue > 500 ? buyData.step + 1 : buyData.step + 1;
          setBuyData({ ...buyData, step: step })
        } else {
          setIsValidAddressMsg("Please Enter a valid wallet address");
        }
      } else
        setIsValidAddressMsg("Blank spaces are not allowed");
    else
      setIsValidAddressMsg("Please Enter a wallet address");
  }

  return (
    <>
      <Form className="exchange-form" onSubmit={e => {
        e.preventDefault();
        validateWalletAddress();
      }}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Wallet Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Wallet Address"
            value={buyData.receiver_wallet_address}
            onChange={e => setBuyData({
              ...buyData,
              receiver_wallet_address: e.target.value
            })}
          />
        </Form.Group>
        {isValidAddressMsg ?
          <div className="error-card">
            <Image
              src={window.location.origin + "/img/warning-icon.svg"}
              className="exchange-warning-icon"
            />
            <span>{isValidAddressMsg}</span>
          </div>
          : null
        }
        <div className="exchange-btn-sec">
          <Button className="default-btn" type="submit">
            Continue
          </Button>
          <p>By continuing you agree to our <Link to="#">cookie policy.</Link></p>
        </div>
      </Form>
    </>
  );
}

export default WalletAddressSec;
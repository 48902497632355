import React, { useState, useEffect } from "react";
import { Image, Form, Container, Row, Col, Tab, Nav, Button, InputGroup } from "react-bootstrap";
import "./Swap.css";
import { Link } from "react-router-dom";

const SwapScan = (props) => {


    return (
        <>
            <div className="buy-crypto-sec">
                <Row className="justify-content-md-center">
                    <Col md={5}>
                        <div className="exchange-box swap-exchange-box">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="swap-crypto">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="buy-crypto">Buy Crypto</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="swap-crypto">Swap Crypto</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Tab.Container>
                            <Form className="exchange-form">
                                <div className="receive-token-sec">
                                    <h5>Crypto Payment</h5>
                                </div>
                                <div className="swap-scan-item">
                                    <div className="swap-scan-box">
                                        <Image
                                            src={window.location.origin + "/img/sell/scan-qr-code.png"}
                                            className="swap-scan-icon"
                                        />
                                        <p>Scar QR Code</p>
                                    </div>
                                    <div className="swap-connect-card">
                                        <Image
                                            src={window.location.origin + "/img/sell/connect-metamask-icon-1.png"}
                                            className="swap-connect-icon"
                                        />
                                         <p>Connect Metamask</p>
                                    </div>
                                </div>
                                <InputGroup className="mb-4">
                                    <Form.Control
                                    placeholder="763ehj fewjebciwetr78324bfjbfwj3kefnw"
                                    />
                                    <InputGroup.Text id="basic-addon2">
                                        <Image
                                            src={window.location.origin + "/img/sell/copy-icon.svg"}
                                            className="swap-connect-icon"
                                        />
                                    </InputGroup.Text>
                                </InputGroup>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Paste Your Transaction Hash</Form.Label>
                                    <Form.Control type="email" placeholder="763ehj fewjebciwetr78324bfjbfwj3kefnw" />
                                </Form.Group>
                                <div className="exchange-btn-sec">
                                    <Button className="default-btn" onClick={() => props.setStep(4)}>
                                        Continue
                                    </Button>
                                    <p>By continuing you agree to our <Link to="#">cookie policy.</Link></p>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default SwapScan;

import React, { useState, useEffect } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { updateSwapData } from '../../store/actions/UserAction';
import Web3 from "web3";
import WalletConnectorIndex from '../WalletConnector/WalletConnectorIndex';


const SwapWalletAddress = () => {
  const dispatch = useDispatch();
  const swapData = useSelector(state => state.user.swapData);
  const setSwapData = data => dispatch(updateSwapData(data));
  const connectedWallet = useSelector(state => state.walletConnector.connectedWallet);

  const [isValidAddressMsg, setIsValidAddressMsg] = useState();

  useEffect(() => {
    if (connectedWallet.address) {
      if (swapData.receiver_wallet_address && connectedWallet.address !== swapData.receiver_wallet_address) {
        console.log(swapData.receiver_wallet_address, connectedWallet.address, swapData.receiver_wallet_address)
        if (window.confirm("New wallet address connected. Do you want to use it?"))
          setSwapData({
            ...swapData,
            receiver_wallet_address: connectedWallet.address,
          });
      } else {
        setSwapData({
          ...swapData,
          receiver_wallet_address: connectedWallet.address,
        });
      }
    }
  }, [connectedWallet.address]);

  // useEffect(() => {
  //   if (!connectedWallet.address) {
  //     if (connectedWallet.lastConnectedAddress === swapData.receiver_wallet_address) {
  //       setSwapData({
  //         ...swapData,
  //         receiver_wallet_address: "",
  //       });
  //     }
  //   }
  // }, [connectedWallet]);

  const account = useAccount({
    onDisconnect() {
      console.log("Disconnected");
      setSwapData({
        ...swapData,
        receiver_wallet_address: ""
      });
    }
  });

  // useEffect(() => {
  //   if (account.address) {
  //     setSwapData({
  //       ...swapData,
  //       receiver_wallet_address: account.address
  //     });
  //   }
  // }, [account.address]);

  console.log(swapData);


  const validateWalletAddress = e => {
    e.preventDefault();

    if (swapData.receiver_wallet_address)
      if (swapData.receiver_wallet_address.trim() === swapData.receiver_wallet_address) {
        const web3 = new Web3(window.ethereum);
        if (web3.utils.isAddress(swapData.receiver_wallet_address)) {
          setSwapData({ ...swapData, step: 3 })
        } else {
          setIsValidAddressMsg("Please Enter a valid wallet address");
        }
      } else
        setIsValidAddressMsg("Blank spaces are not allowed");
    else
      setIsValidAddressMsg("Please Enter a wallet address");
  }

  return (
    <div className="swap-exchange-box">
      <Form className="exchange-form" onSubmit={validateWalletAddress}>
        <div className="receive-token-sec">
          <h5>Recieve Tokens</h5>
        </div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Recievers Wallet</Form.Label>
          <Form.Control
            type="text"
            placeholder="wallet address"
            value={swapData.receiver_wallet_address}
            onChange={e => setSwapData({
              ...swapData,
              receiver_wallet_address: e.target.value,
            })}
          />
        </Form.Group>
        {isValidAddressMsg ?
          <div className="error-card">
            <Image
              src={window.location.origin + "/img/warning-icon.svg"}
              className="exchange-warning-icon"
            />
            <span>{isValidAddressMsg}</span>
          </div>
          : null
        }
        <div className="swap-transfer-icon-sec">
          <span className="or-line">
            <span>
              Or
            </span>
          </span>
        </div>
        <div className="swap-connect-box">
          Connect Metamask To Recieve The Tokens
        </div>
        <div className="exchange-btn-sec">
          <div className="connect-meta-btn">
            <WalletConnectorIndex chainId={swapData.swap_token_type.id} className="connect-meta-icon" />
          </div>
          <Button className="default-btn" type="submit">
            Continue
          </Button>
          <p>By continuing you agree to our <Link to="#">cookie policy.</Link></p>
        </div>
      </Form>
    </div>
  );
}

export default SwapWalletAddress;
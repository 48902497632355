import React from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from 'react-global-configuration';

const FooterIndex = () => {
	return (
		<>
			<footer className="footer-sec">
				<Container>
					<div className="footer-about-box">
						<Row>
							<Col md={6}>
								<div className="footer-about-sec">
									<div className="footer-about-top-box">
										{configuration.get("configData.footer_pages1").length > 0 && (
											<div className="footer-about-card">
												<h3>Company</h3>
												<ul className="list-unstyled footer-about-list">
													{configuration.get("configData.footer_pages1").map((footer_page) => (
														<li>
															<Link
																to={`/page/${footer_page.static_page_unique_id}`}
																target="_blank"
															>
																{footer_page.title}
															</Link>
														</li>
													))}
												</ul>
											</div>
										)}
										{configuration.get("configData.footer_pages2").length > 0 && (
											<div className="footer-about-card">
												<h3>News</h3>
												<ul className="list-unstyled footer-about-list">
													{configuration.get("configData.footer_pages2").map((footer_page) => (
														<li>
															<Link
																to={`/page/${footer_page.static_page_unique_id}`}
																target="_blank"
															>
																{footer_page.title}
															</Link>
														</li>
													))}
												</ul>
											</div>
										)}
									</div>
								</div>
							</Col>
							<Col md={6}>
								<div className="footer-about-bottom-box">
									<div className="footer-logo-sec">
										<Image
											src={configuration.get("configData.site_logo")}
											className="footer-logo"
										/>
									</div>
									<div className="footer-copyright-sec">
										<p>{configuration.get("configData.footer_description")}</p>
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<div className="footer-social-link-sec">
						<ul className="list-unstyled footer-social-link">
							{configuration.get("configData.twitter_link") && (
								<li>
									<a 
										href={configuration.get("configData.twitter_link")}
										target="_blank"
									>
										<Image
											src={window.location.origin + "/img/twitter.svg"}
											className="footer-logo"
										/>
									</a>
								</li>
							)}
							{configuration.get("configData.linkedin_link") && (
								<li>
									<a href={configuration.get("configData.linkedin_link")} target="_blank">
										<Image
											src={window.location.origin + "/img/linkedin.svg"}
											className="footer-logo"
										/>
									</a>
								</li>
							)}
							{configuration.get("configData.facebook_link") && (
								<li>
									<a href={configuration.get("configData.facebook_link")} target="_blank">
										<Image
											src={window.location.origin + "/img/facebook.svg"}
											className="footer-logo"
										/>
									</a>
								</li>
							)}
							{configuration.get("configData.instagram_link") && (
								<li>
									<a href={configuration.get("configData.instagram_link")} target="_blank">
										<Image
											src={window.location.origin + "/img/instagram.svg"}
											className="footer-logo"
										/>
									</a>
								</li>
							)}
						</ul>
					</div>
				</Container>
			</footer>
		</>
	);
};

export default FooterIndex;

import React, { useEffect, useState } from 'react';
import { Image, Button } from 'react-bootstrap';
import CustomSelect from '../Common/CustomSelect';
import { buyChains } from '../Common/Chains';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateBuyData } from '../../store/actions/UserAction';
import { amountConversionStart } from '../../store/actions/WalletConnectorAction';
import { initialBuyData } from '../../store/reducers/UserReducer';

const ExchangeRateSec = ({ redirect = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buyData = useSelector(state => state.user.buyData);
  const amountConversion = useSelector(state => state.walletConnector.amountConversion);

  const [skipRender, setSkipRender] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const setBuyData = data => dispatch(updateBuyData(data));

  useEffect(() => {
    setTimeout(() => {
      if (!buyData.token_type) {
        setBuyData({
          ...buyData,
          token_type: buyChains[0],
        })
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (!skipRender) {
      dispatch(amountConversionStart({
        convertFrom: buyData.token_type.apiId,
        convertTo: "usd",
        isNetwork: true,
      }));
    }
  }, [buyData.token_type, buyData.token]);

  useEffect(() => {
    if (!skipRender && !amountConversion.loading && Object.keys(amountConversion.data).length > 0) {
      const exchangeValue = Math.round((amountConversion.data.amount * buyData.token) * Math.pow(10, 8)) / Math.pow(10, 8)
      setBuyData({
        ...buyData,
        exchangeValue: exchangeValue,
        amountConversion: amountConversion.data.amount,
      });
      if (exchangeValue < 1)
        setErrorMsg("Min 1 USD");
      else
        setErrorMsg("");
    }
    setSkipRender(false);
  }, [amountConversion]);

  const onTokenChange = value => {
    if (Number(value) == value) {
      setBuyData({
        ...initialBuyData,
        token: value,
        token_type: buyData.token_type,
        exchangeValue: buyData.exchangeValue,
      });
    }
  }

  const onComplete = e => {
    e.preventDefault();
    if (redirect) {
      if (buyData.step == 6) {
        setBuyData({
          ...initialBuyData,
          token: buyData.token,
          token_type: buyData.token_type,
          exchangeValue: buyData.exchangeValue,
        })
      }
      navigate("/buy");
    }
    else {
      if (buyData.exchangeValue >= 1) {
        setBuyData({ ...buyData, step: buyData.step + 1 })
      }
    }
  }


  return (
    <div className="exchange-conversion-box">
      <div className="exchange-conversion-card">
        <div className="border-theme-right">
          <div className="exchange-conversion-left-sec">
            <div className="exchange-conversion-label">
              <Image
                src={buyData.token_type?.icon}
                className="exchange-conversion-icon"
              />
              {buyData.token_type?.symbol}
            </div>
            <div className="exchange-select-sec">
              <CustomSelect
                options={buyChains}
                value={buyData.token_type}
                onChange={val => setBuyData({
                  ...initialBuyData,
                  token: buyData.token,
                  token_type: val,
                  exchangeValue: buyData.exchangeValue,
                })}
              />
            </div>
          </div>
        </div>
        <div className="exchange-conversion-right-sec">
          <h5>You Get</h5>
          <input
            className="form-exchange-input"
            value={buyData.token}
            autoFocus={true}
            onChange={e => onTokenChange(e.target.value)} />
          {/* <h3>0.1</h3> */}
        </div>
      </div>
      <div className="swap-transfer-icon-sec">
        <span className="or-line">
          <span>
            <Image
              src={window.location.origin + "/img/exchange/swap-transfer-icon.svg"}
              className="swap-icon"
            />
          </span>
        </span>
      </div>
      <div className="exchange-conversion-card">
        <div className="border-theme-right">
          <div className="exchange-conversion-left-sec">
            <div className="exchange-conversion-label">
              <Image
                src={window.location.origin + "/img/exchange/dollar-icon.svg"}
                className="exchange-conversion-icon"
              />
              USD
            </div>
            <div className="exchange-select-sec">
              <h3>Dollars</h3>
            </div>
          </div>
        </div>
        <div className="exchange-conversion-right-sec">
          <h5 className="text-para">You Send</h5>
          <h3>{buyData.exchangeValue}</h3>
        </div>
      </div>
      <div className="exchange-btn-sec">
        <Button
          className="default-btn"
          onClick={buyData.token > 0 ? onComplete : ""}
          disabled={amountConversion.buttonDisable}
        >
          {redirect ? "Buy" : "Continue"}
        </Button>
        {!redirect ?
          <p>By continuing you agree to our <Link to="/policy">cookie policy.</Link></p>
          : null
        }
      </div>
      {errorMsg ?
        <div className="error-card mt-3">
          <Image
            src={window.location.origin + "/img/warning-icon.svg"}
            className="exchange-warning-icon"
          />
          <span>{errorMsg}</span>
        </div>
        : null
      }
    </div>
  );
}

export default ExchangeRateSec;
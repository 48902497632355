import React, { useState, useEffect } from "react";
import { Image, Form, Container, Row, Col, Tab, Nav, Button } from "react-bootstrap";
import "./Swap.css";
import { Link } from "react-router-dom";

const SwapWalletAddress = (props) => {


    return (
        <>
            <div className="buy-crypto-sec">
                <Row className="justify-content-md-center">
                    <Col md={5}>
                        <div className="exchange-box swap-exchange-box">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="swap-crypto">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="buy-crypto">Buy Crypto</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="swap-crypto">Swap Crypto</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Tab.Container>
                            <Form className="exchange-form">
                                <div className="receive-token-sec">
                                    <h5>Recieve Tokens</h5>
                                </div>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Recievers Wallet</Form.Label>
                                    <Form.Control type="email" placeholder="763ehj fewjebciwetr78324bfjbfwj3kefnw" />
                                </Form.Group>
                                <div className="swap-transfer-icon-sec">
                                    <span className="or-line">
                                        <span>
                                            Or
                                        </span>
                                    </span>
                                </div>
                                <div className="swap-connect-box">
                                    Connect Metamask To Recieve The Tokens
                                </div>
                                <div className="exchange-btn-sec">
                                    <div className="connect-meta-btn">
                                        <Button>
                                            <Image
                                                src={window.location.origin + "/img/sell/connect-metamask-icon.png"}
                                                className="connect-meta-icon"
                                            />
                                            Connect Metamask
                                        </Button>
                                    </div>
                                    <Button className="default-btn" onClick={() => props.setStep(3)}>
                                        Continue
                                    </Button>
                                    <p>By continuing you agree to our <Link to="#">cookie policy.</Link></p>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default SwapWalletAddress;
